/**
 * Color name source http://www.htmlcsscolor.com/
 */

export default {
  light: "#FFFFFF",
  highempahis: "#333333",
  highgray: "#969696",
  midgray: "#B5B5B5",
  lowgray: "#CBCBCB",
  downgray: "#EDEDED",
  component: "#F5F5F5",
  primary: "#00ab6b",
  "primary60%": "#00ab6b99",
  secondary: "#3b5998",
  trading: "#2facb2",
  gainsboro: "#DADADA",
  whisper: "#EEEEEE",
  silver: "#BBBBBB",
  aliceBlue: "#F5F6F7",
  snow: "#F9F9F9",
  dimgray: "#6b6b6b",
  black: "#000000",
  nightRider: "#333333",
  zambezi: "#595959",
  veryLightGrey: "#CCCCCC",
  suvaGrey: "#939393",
  highball: "#979134",
  sunsetOrange: "#ee4a49",
  clearDay: "#e6f8f1",
  darkGray: "#AAAAAA",
  dimGray: "#666666",
  mintCream: "#eafff7",
  nobel: "#999999",
  bubbles: "#DEF2E5",
  heather: "#B5B6B7",
  bermuda: "#80D5B5",
  sundown: "#F7A5A4",
  hummingBird: "#cceee1",
  coralCandy: "#FAC8C8",
  darkGrey: "#AAAAAA",
  valencia: "#d9534f",
  mischka: "#a2a7b0",
  royalBlue: "#5766D3",
  steelBlue: "#4178bb",
  scooter: "#2c8aa3",
  java: "#169b8b",
  jade: "#00ad73",
  /* handling hex code with duplicate name */
  "seasonality-darkest-green": "#006c00",
  "seasonality-medium-green": "#018601",
  "seasonality-green": "#009b00",
  "seasonality-bright-green": "#01ad01",
  "seasonality-darkest-red": "#a70000",
  "seasonality-medium-red": "#c20d0d",
  "seasonality-red": "#d00808",
  "seasonality-bright-red": "#e70000",
  amber: "#ffbf00",
  mintTulip: "#bde9d9",
  dodgerBlue: "#1E90FF",
  navyBlue: "#136ad5",
  whiteSmoke: "#F2F2F2",
  pigmentGreen: "#019934",
  lightGreen: "#90E897",
  monaLisa: "#ff988e",
  purpleHeart: "#7924c3",
  venetianRed: "#c11214",
  green: "#0c8414",
  cadetBlue: "#49a3ac",
  pinkSwan: "#B6B6B6",
  grannyApple: "#C7F3CB",
  alertRed: "#FCD0CE",
  alertOrange: "#F0AD4E",
  alertBlue: "#337AB7",
  yellowSea: "#eba031",
  chardonnay: "#ffc774",
  softgreen: "#E5F7F0",
  mountainMeadow: "#0bba7a",
  grey: "#777777",
  nero: "#222222",
  ghostWhite: "#f8f8f9",
  azureBlue: "#2F8EF7",
  bitterSweet: "#fc605c",
  supernova: "#fdbc40",
  limeGreen: "#34c84a",
  softGainsboro: "#DDDDDD",
  mediumAquamarine: "#5dcbad",
  cosmicLatte: "#e1f6e5",
  vividViolet: "#712E9E",
};
