import dynamic from 'next/dynamic';
import SEO from 'global/SEO';
import HomePageLayout from 'global/HomePageLayout';

const Header = dynamic(() => import('./Header'), {
  loading: () => <div />,
});
const Price = dynamic(() => import('./Price'), {
  loading: () => <div />,
});
const CommunityBenefit = dynamic(() => import('./CommunityBenefit'), {
  loading: () => <div />,
});
const Transaction = dynamic(() => import('./Transaction'), {
  loading: () => <div />,
});
const Stocks = dynamic(() => import('./Stocks'), {
  loading: () => <div />,
});
const Tools = dynamic(() => import('./Tools'), {
  loading: () => <div />,
});
const Reports = dynamic(() => import('./Reports'), {
  loading: () => <div />,
});
const Notification = dynamic(() => import('./Notification'), {
  loading: () => <div />,
});
const KeyFeatures = dynamic(() => import('./KeyFeatures'), {
  loading: () => <div />,
});
const Benefit = dynamic(() => import('./Benefit'), {
  loading: () => <div />,
});
const Featured = dynamic(() => import('./Featured'), {
  loading: () => <div />,
});
const Learning = dynamic(() => import('./Learning'), {
  loading: () => <div />,
});

const DesktopApp = dynamic(() => import('./DesktopApp'), {
  loading: () => <div />,
});

const HomePage = () => (
  <HomePageLayout startInvestingText="Mulai Berinvestasi">
    <SEO />
    <Header />
    <Price />
    <DesktopApp />
    <CommunityBenefit />
    <Transaction />
    <Stocks />
    <Tools />
    <Reports />
    <Notification />
    <KeyFeatures />
    <Benefit />
    <Featured />
    <Learning />
  </HomePageLayout>
);

export default HomePage;
