import styled from "styled-components";
import handleSpaces, { spacingProps } from "../utils/handleSpaces";

const Flex = styled.div.attrs((props) => {
  return {
    direction: props.direction || "row",
    justify: props.justify || "left",
    align: props.align || "flex-start",
    padding: props.padding || "0",
    margin: props.margin || "0",
    width: props.width,
    height: props.height,
    wrap: props.wrap || "nowrap",
    ...spacingProps(props),
  };
})`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  flex-wrap: ${({ wrap }) => wrap};
  ${handleSpaces}
`;

export default Flex;
