const types = {
  m: "margin",
  p: "padding",
};

const directions = {
  t: ["-top"],
  r: ["-right"],
  b: ["-bottom"],
  l: ["-left"],
  x: ["-left", "-right"],
  y: ["-top", "-bottom"],
};

const EMPTY_STRING = "";
const WHITE_SPACE = " ";
const REGEX = /^([mp][trblxy]?)$/;

const pipe = (...fns) => (x) => fns.reduce((prev, f) => f(prev, x), x);

const filter = (keys) => keys.filter((key) => key.match(REGEX));

const merge = (props) =>
  props.map(({ type, direction, value }) =>
    direction.map((d) => `${type}${d}: ${value};`).join(WHITE_SPACE),
  );

const splitPropKey = (keys, props) =>
  keys.map((key) => {
    const [type, direction] = key.split("");

    return {
      type: types[type],
      direction: directions[direction] || [EMPTY_STRING],
      value: props[key],
    };
  });

const handleSpaces = (props) =>
  pipe(Object.keys, filter, splitPropKey, merge)(props).join(WHITE_SPACE);

export const spacingProps = (props) => {
  const typesKeys = Object.keys(types);
  const directionsKeys = Object.keys(directions);

  const result = typesKeys
    .map((type) => directionsKeys.map((dir) => ({ [`${type}${dir}`]: props[`${type}${dir}`] })))
    .reduce((prev, curr) => [...prev, ...curr], [])
    .filter((prop) => Object.values(prop)[0])
    .reduce((prev, curr) => ({ ...prev, ...curr }), {});

  return { ...result };
};

export default handleSpaces;
