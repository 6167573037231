import styled, { css } from 'styled-components';

import color from '../_style/color';
import handleSpaces, { spacingProps } from '../utils/handleSpaces';

const familyType = {
  regular: 'ProximaNovaRg',
  light: 'ProximaNovaLt',
  bold: 'ProximaNovaBd',
};

export default styled.p
  .withConfig({
    shouldForwardProp: (prop) => !['ellipsis', 'textTransform'].includes(prop),
  })
  .attrs((props) => ({
    size: props.size || '14px',
    weight: props.weight || 'normal',
    height: props.height || 'normal',
    color: props.color || color.highempahis,
    align: props.align || 'left',
    padding: props.padding || 0,
    width: props.width,
    ellipsis: props.ellipsis,
    textTransform: props.textTransform,
    family: props.family || 'regular',
    ...spacingProps(props),
  }))`
  font-family: ${({ family }) => familyType[family]};
  font-size: ${({ size }) => size};
  font-weight: ${({ weight }) => weight};
  line-height: ${({ height }) => height};
  color: ${(props) => props.color};
  text-align: ${({ align }) => align};
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
  text-transform: ${({ textTransform }) => textTransform};
  margin: 0;

  ${(props) =>
    css`
      ${handleSpaces(props)}
    `}

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  & > a {
    color: ${color.primary};
    text-decoration: none;
  }

  ${(props) =>
    css`
      ${handleSpaces(props)}
    `}
`;
