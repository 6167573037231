const STAGE = process.env.NODE_ENV || 'staging';
const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || '';
const SOCKET_URL = process.env.NEXT_PUBLIC_GENERAL_SOCKET;

// Separate the version with base URL, if we need more version
// just add from `.env`, then add them into this file
const API_VERSION = process.env.NEXT_PUBLIC_API_VERSION;
const API_VERSION_ALT = process.env.NEXT_PUBLIC_API_VERSION_ALT || API_VERSION;
const API_BASE_URL = `${process.env.NEXT_PUBLIC_API_URL}/${API_VERSION}`;

const API_EXODUS_URL = process.env.NEXT_PUBLIC_EXODUS_API_URL;
const API_TRADING_URL = process.env.NEXT_PUBLIC_TRADING_API_URL;
const API_TRADING_URL_V2 = process.env.NEXT_PUBLIC_TRADING_API_URL_V2;
const API_SECURITIES_ALT_URL = `${process.env.NEXT_PUBLIC_API_URL}/${API_VERSION_ALT}`;
// Can be used with social or trading authorization
const API_SECURITIES_GENERAL_URL = process.env.NEXT_PUBLIC_SMART_ORDER_API_URL;
const API_CURATOR_URL = process.env.NEXT_PUBLIC_CURATOR_API_URL;

const AVATAR_URL =
  process.env.NEXT_PUBLIC_AWS_AVATAR_BUCKET_PUBLIC || 'localhost';
const AWS_AVATAR_BUCKET =
  process.env.NEXT_PUBLIC_AWS_AVATAR_BUCKET || 'localhost';
const AWS_IMAGES_BUCKET = process.env.NEXT_PUBLIC_AWS_IMAGES_BUCKET;
const AWS_TRADE_REG_BUCKET = process.env.NEXT_PUBLIC_AWS_TRADE_REG_BUCKET;
const AWS_EBOOK_BUCKET = process.env.NEXT_PUBLIC_AWS_EBOOK_BUCKET;
const AWS_CHAT_BUCKET = process.env.NEXT_PUBLIC_AWS_CHAT_BUCKET;

const GIPHY_URL = 'https://api.giphy.com/v1/gifs';
const GIPHY_STICKERS_URL = 'https://api.giphy.com/v1/stickers';
const GIPHY_API_KEY = process.env.NEXT_PUBLIC_GIPHY_KEY || '';

const GOPAY_URL_JAVASCRIPT = process.env.NEXT_PUBLIC_GOPAY_URL_JAVASCRIPT || '';
const GOPAY_QR_CODE_URL = process.env.NEXT_PUBLIC_GOPAY_QR_CODE_URL || '';
const GOPAY_REF_ID_URL = process.env.NEXT_PUBLIC_GOPAY_REF_ID_URL || '';

const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APPID || '';

// link to download stockbit app
const GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=com.stockbit.android&hl=en';
const APP_STORE_URL =
  'https://itunes.apple.com/us/app/stockbit/id1184800207?mt=8';

const STOCKBIT_ACADEMY_URL = process.env.NEXT_PUBLIC_ACADEMY_URL;

const CUSTOM_USER_AGENT = process.env.NEXT_PUBLIC_USER_AGENT;

const DESKTOP_APP_BUCKET = process.env.NEXT_PUBLIC_DESKTOP_APP_BUCKET;
const DESKTOP_APP_SECRET_KEY = process.env.NEXT_PUBLIC_DESKTOP_APP_SECRET_KEY;
const DESKTOP_APP_ACCESS_KEY = process.env.NEXT_PUBLIC_DESKTOP_APP_ACCESS_KEY;
const DESKTOP_APP_URL = process.env.NEXT_PUBLIC_DESKTOP_APP_URL;
const DESKTOP_APP_END_POINT = process.env.NEXT_PUBLIC_DESKTOP_APP_END_POINT;

const CURATOR_TOKEN = process.env.NEXT_PUBLIC_CURATOR_TOKEN;
const CURATOR_FEED_ID = process.env.NEXT_PUBLIC_CURATOR_FEED_ID;
const APPLE_APP_SITE_ID = process.env.NEXT_PUBLIC_APPLE_APP_SITE_ID;

export {
  STAGE,
  API_BASE_URL,
  API_EXODUS_URL,
  API_TRADING_URL,
  API_TRADING_URL_V2,
  API_SECURITIES_ALT_URL,
  API_SECURITIES_GENERAL_URL,
  SITE_URL,
  SOCKET_URL,
  STOCKBIT_ACADEMY_URL,
  // buckets
  AVATAR_URL,
  AWS_AVATAR_BUCKET,
  AWS_CHAT_BUCKET,
  AWS_IMAGES_BUCKET,
  AWS_TRADE_REG_BUCKET,
  AWS_EBOOK_BUCKET,
  // 3rd parties
  GIPHY_URL,
  GIPHY_STICKERS_URL,
  GIPHY_API_KEY,
  GOPAY_URL_JAVASCRIPT,
  GOPAY_QR_CODE_URL,
  GOPAY_REF_ID_URL,
  FACEBOOK_APP_ID,
  // store url
  GOOGLE_PLAY_URL,
  APP_STORE_URL,
  API_VERSION_ALT,
  CUSTOM_USER_AGENT,
  // desktop app download
  DESKTOP_APP_BUCKET,
  DESKTOP_APP_SECRET_KEY,
  DESKTOP_APP_ACCESS_KEY,
  DESKTOP_APP_URL,
  DESKTOP_APP_END_POINT,
  // Curator.io
  API_CURATOR_URL,
  CURATOR_TOKEN,
  CURATOR_FEED_ID,
  // Apple App Site
  APPLE_APP_SITE_ID,
};
