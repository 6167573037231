const defaultMetaTags = {
  title:
    'Stockbit - Investasi Saham Bersama Komunitas Saham Terbesar di Indonesia',
  description:
    'Aplikasi saham untuk berdiskusi, analisa dan berinvestasi dengan harga real time, berita terkini, riset, data fundamental, charting, dan analytical tools yang lengkap',
  keywords:
    'stockbit, investasi, saham, pasar modal, pasar saham, harga saham, belajar investasi, belajar saham, nabung saham, rekomendasi saham, belajar trading, virtual trading, komunitas saham, ihsg, berita saham, analisa saham, jual beli saham, bei, bursa saham indonesia, idx, professional tools, tools saham, saham bumi, reksadana, jkse',
  image: 'https://stockbit.com/assets/img/stockbit.jpg',
  url: 'https://stockbit.com',
};

const glossaryMetaTags = {
  ...defaultMetaTags,
  title: 'Stockbit Glossary | Stockbit - Kamus Investasi Saham',
  url: 'https://stockbit.com/glossary',
};

// eslint-disable-next-line import/prefer-default-export
export { defaultMetaTags, glossaryMetaTags };
