import { FACEBOOK_APP_ID } from 'constants/api';
import { isProduction } from 'constants/app';
import Head from 'next/head';
import { defaultMetaTags } from './constants';

interface SEOProps {
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
  url?: string;
  schemaJson?: any;
  customTitle?: boolean;
}

function SEO({
  title,
  description,
  keywords,
  image,
  url,
  schemaJson,
  customTitle,
}: SEOProps) {
  return (
    <Head>
      <title>
        {title !== defaultMetaTags.title && !schemaJson && !customTitle
          ? `${title} | ${defaultMetaTags.title}`
          : title}
      </title>
      {/* Default meta tags */}
      {isProduction ? (
        <meta name="robots" content="index, follow" />
      ) : (
        <meta name="robots" content="noindex, nofollow" />
      )}
      <meta name="revisit" content="1 days" />
      <meta name="revisit-after" content="1 days" />
      <meta name="resource-type" content="document" />
      <meta name="audience" content="general" />
      <meta name="content-language" content="id-ID" />
      <meta name="web_author" content="Stockbit" />
      <meta name="type" content="website" />
      <meta name="copyright" content="Stockbit" />
      <meta name="google" content="notranslate" />

      <meta
        name="google-site-verification"
        content="n9LVcB1gB0We60y2dG78-koFgM8y_mrf9pw1hrKsxFA"
      />
      <meta name="fb:app_id" content={FACEBOOK_APP_ID} />

      {/* Dynamic meta tags */}
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      {/* Opengraph meta tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:image:secure_url" content={image} />

      {/* Twitter meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {schemaJson ? (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaJson) }}
        />
      ) : null}
    </Head>
  );
}

SEO.defaultProps = {
  ...defaultMetaTags,
  customTitle: false,
};

export default SEO;
